<template>
  <footer>
    <div class="container">
      <div class="footer-top">
        <div class="footer-top__left">
          <page-logo theme="dark" />
        </div>
        <div class="footer-top__rigth">
          <ul class="footer-nav">
            <li>
              <router-link to="/about-us" class="nav-item__link">
                {{ $t("aboutSystem") }}
              </router-link>
            </li>
            <li>
              <router-link to="/all-news" class="nav-item__link">
                {{ $t("adsPage") }}
              </router-link>
            </li>
            <li>
              <router-link to="/reports-filed/0/meta-data" class="nav-item__link">
                {{ $t("officialStatisticsData") }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-left">
          <ul>
            <li>
              {{ $t("phoneForHelp") }}:
              <a target="_blank" href="tel:99871203-80-00"><span>+998 (71) 203-80-00</span></a>
            </li>
            <li>
              {{ $t("mail") }}:
              <a target="_blank" href="mailto:info@stat.uz"><span>info@stat.uz</span></a>
            </li>
            <li>{{ $t("onlineNow") }}: {{ activeUsers }}</li>
          </ul>
          <p>© {{new Date().getFullYear()}}. {{ $t("allRightsReserved") }}</p>
        </div>
        <div class="footer-bottom-middle">
          <p>
            {{ $t("MaterialsSiteAvailableUnderFollowingLicenses") }}: <br />
            <a target="_blank" rel="license" href="https://creativecommons.org/licenses/by/4.0/"
              ><span>Creative Commons Attribution 4.0 International (CC BY 4.0)</span></a
            >
          </p>
          <div>


            <a href="https://ping-admin.com/free_uptime/stat/6fff0195574aa98d9b6a24565676927f12906.html" target="_blank"><img src="//images.ping-admin.ru/i/free_uptime/1b95e72fdb353caf83341ea77b980fcc12906_101.gif" width="88" height="31" border="0" alt="проверка сайта на доступность"></a>
            <a target="_blank" rel="license" href="https://creativecommons.org/licenses/by/4.0/"
              ><img src="@/assets/icons/88x31.png"
            /></a>
            <a target="_blank" href="http://certificates.theodi.org/en/datasets/221261/certificate"
              ><img src="@/assets/icons/badge.png" alt=""
            /></a>
          </div>
          <p class="base-link" v-if="currentLang == 'uz'">
            Materiallardan foydalanganda {{ host }} havolani keltirish majburiy.
          </p>
          <p class="base-link" v-if="currentLang == 'ru'">
            При использовании материалов ссылка на {{ host }} обязательна.
          </p>
          <p class="base-link" v-if="currentLang == 'en'">
            When using the materials, it is mandatory to cite the link {{ host }}.
          </p>
        </div>
        <div class="footer-bottom-right">
          <div>
            <p>{{ $t("socialNetworks") }}:</p>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/uzstataxborot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span class="icon icon-facebook"></span
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/accounts/login/?next=/uzstataxborot/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span class="icon icon-instagram"></span
                ></a>
              </li>
              <li>
                <a href="https://t.me/uzstataxborot" target="_blank" rel="noopener noreferrer">
                  <span class="icon icon-teleram"></span
                ></a>
              </li>
            </ul>
          </div>
          <div>
            <!-- <p>{{ $t("linkPixYz") }}:</p>
            <a href="https://pixyz.uz/" target="_blank" rel="noopener noreferrer">
              <span class="icon icon-logoPixYz"></span>
            </a> -->
          </div>
          <p class="info-error">
            {{ $t("siteErrorSendMessage") }}
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import PageLogo from '@/components/PageLogo';
import Api from '@/api/Api';

export default {
  name: 'HomeFooter',
  data() {
    return {
      activeUsers: 0,
      currentLang: localStorage.getItem('language'),
      host: window.location.origin,
    };
  },
  components: {
    PageLogo,
  },
  methods: {
    async getactiveUsers() {
      const { data } = await Api.getActiveUsers();
      this.activeUsers = data.online_now;
    },
  },
  mounted() {
    this.getactiveUsers();
  },
};
</script>

<style lang="scss" src="./HomeFooter.scss"></style>

