<template>
  <div class="faqs">
    <public-header />
    <div class="banner main">
      <div class="container">
        <v-breadcrumbs :items="breadCrumb" class="main-breadcrumb">
          <template v-slot:divider>
            <img src="@/assets/icons/breadcrumb-devider.svg" alt="bread crumb" />
          </template>
        </v-breadcrumbs>
      </div>
    </div>
    <main>
      <div class="container">
        <div class="faqs-header d-flex align-center justify-space-between">
          <h2 class="faqs-title">{{ $t('frequentlyQuestions') }}</h2>
        </div>
        <div
          class="faqs-wrapper"
          v-for="item in faqList"
          :key="item.id"
          @click="changeActive(item)"
          :class="{ active: item.active }"
        >
          <div class="d-flex">
            <h2 class="faq__title">
              {{ item.title }}
            </h2>
            <div class="arrow-wrapper">
              <span class="icon icon-faqArrow"></span>
            </div>
          </div>
          <p class="faq__text">
            {{ item.text }}
          </p>
        </div>
        <button
          v-if="faqList.length > showData"
          @click="showMoreData"
          class="more-button">
            {{ $t('otherQuestions') }}
        </button>
      </div>
    </main>
    <home-footer />
  </div>
</template>

<script>
import PublicHeader from '@/components/PublicHeader';
import HomeFooter from '@/components/HomeFooter';
import Api from '@/api/Api';

export default {
  components: {
    PublicHeader,
    HomeFooter,
  },
  name: 'FaqsView',
  data() {
    return {
      showData: 20,
      breadCrumb: [
        {
          text: this.$t('main'),
          disabled: false,
          link: true,
          exact: true,
          to: {
            path: '/',
          },
        },
        {
          text: this.$t('frequentlyQuestions'),
          disabled: true,
          link: true,
          exact: true,
          to: {
            path: '/faqs',
          },
        },
      ],
      faqList: [],
    };
  },
  methods: {
    changeActive(item) {
      // eslint-disable-next-line no-param-reassign
      item.active = !item.active;
    },
    async GetFaqData() {
      const { data } = await Api.getFaqData({ size: this.showData });
      this.faqList = data.results.map((item) => {
        const {
          answer: text,
          id,
          question: title,
        } = item;
        return {
          text,
          title,
          id,
          active: false,
        };
      });
      this.faqList[0].active = true;
    },
    showMoreData() {
      this.showData += 20;
      this.GetFaqData();
    },
  },
  mounted() {
    this.GetFaqData();
  },
};
</script>

<style src="./FaqsView.scss" lang="scss"></style>
